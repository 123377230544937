import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Seo"
import EntryHeader from "../../components/SingleTemplateComponents/EntryHeader"
import ShareBar from "../../components/SingleTemplateComponents/ShareBar"
import ComponentParser from "../../utils/ComponentParser"
import SinglePagination from "../../components/SingleTemplateComponents/SinglePagination"
import RelatedContent from "../../components/SingleTemplateComponents/RelatedContent"
import AudioPlayer from "react-h5-audio-player"

const post = ( { data: { post, previous, next } } ) => {

	return (
		<Layout>
			<Seo 
				title={post.title}
				canonical={post.seo?.canonical}
				uri={post.uri}
				socialImage={post.featuredImage?.node}
				description={post.seo?.metaDesc ? post.seo.metaDesc : post.excerpt.replace(/(<([^>]+)>)/gi, "") }
			/>

			{post?.resourceMeta?.audioFile?.localFile?.publichURL &&
				<AudioPlayer
					src={post.resourceMeta.audiofile.localFile.publicURL}
					showSkipControls={false}
					showJumpControls={false}
					layout="horizontal"
					customIcons={ {play: <PlayIcon />, pause: <PauseIcon /> }}
				/>
			}
			
			<EntryHeader
				title={post.title}
				date={post?.resourceMeta?.displayDate ? post?.resourceMeta?.displayDate : post.date}
				icon={ post?.organizations?.nodes[0]?.organizationMeta?.logo?.localFile }
				format='Article'
				featured={post?.featuredArticle?.featuredArticle}
				featuredImage={post?.featuredImage?.node?.localFile}
				bannerOverlay={post?.featuredArticle?.bannerOverlayColor}
			/>

			<div className={`${post?.featuredArticle?.featuredArticle && 'bg-gold-100 pt-8'}`}>
				{!post?.featuredArticle?.featuredArticle &&
					<ShareBar 
						url={post.uri}
					/>
				}

				<div className={`${ post?.featuredArticle?.featuredArticle ? 'featured-article' : 'lg:-mt-64' } max-w-2xl mx-auto mt-4 pb-16 px-4 md:px-0 min-h-[40vh]`}>
					{post.blocks ?
					post.blocks.map((block, i) => {
						return (
							<div key={i}>
								<ComponentParser
									block={block}
									page={post}
								/>
							</div>
						)
					})
					:
						<div dangerouslySetInnerHTML={ {__html: post.content }  } />
					}
					{ post.resourceMeta?.sourceUrl &&
						<p className="mt-12">
							This content was originally published on  
							
							<Link 
								to={post.resourceMeta.sourceUrl}
								target="_blank"
							>
								{post?.organizations?.nodes[0] ?
									' ' + post.organizations.nodes[0].name
								: 
									post.resourceMeta.sourceUrl
								}
							</Link>
						</p>
					}
				</div>
			</div>
			
			<SinglePagination 
				next={next?.uri}
				previous={previous?.uri}
			/>

			<RelatedContent
				topic={post?.tags?.nodes[0]?.slug}
				category={post?.categories?.nodes[0]?.slug}
				currentPost={post?.databaseId}
			/>
				
		</Layout>
	);
}

export const postQuery = graphql`
	query post(
		$id: String!,
		$previousPostId: String,
		$nextPostId: String
	) {
		post: wpPost( id: { eq: $id } ) {
			... PostContent
		}
		previous: wpPost( id: { eq: $previousPostId}) {
			title
			uri
		}
		next: wpPost(id: {eq: $nextPostId}) {
			title
			uri
		}
	}
`
export default post