import React from 'react'

const EnvelopeIcon = () => {

    return (
        <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M1.70703 1H23.707M1.70703 1V16M1.70703 1L10.5842 8.25806M23.707 1V16M23.707 1L15.2158 8.25806M23.707 16H1.70703M23.707 16L15.2158 8.25806M1.70703 16L10.5842 8.25806M15.2158 8.25806L14.2225 9.10714C13.4868 9.736 12.4063 9.74782 11.657 9.13519L10.5842 8.25806" stroke="#5E6464" strokeWidth="2" strokeLinejoin="round"/>
        </svg>
    )
}

export default EnvelopeIcon