import React from 'react'

const PrintIcon = () => {

    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.7072 8.00016H20.7072V0.999844C20.7072 0.868503 20.6813 0.738449 20.631 0.617112C20.5808 0.495775 20.5071 0.385532 20.4142 0.292682C20.3213 0.199832 20.211 0.126193 20.0896 0.0759741C19.9683 0.0257548 19.8382 -6.14657e-05 19.7069 1.09891e-07H5.70719C5.57585 -6.14657e-05 5.44578 0.0257548 5.32442 0.0759741C5.20306 0.126193 5.09278 0.199832 4.99989 0.292682C4.90699 0.385532 4.8333 0.495775 4.78303 0.617112C4.73275 0.738449 4.70688 0.868503 4.70688 0.999844V8.00016H1.70687C1.4417 8.00016 1.18739 8.1055 0.999879 8.293C0.812372 8.48051 0.707031 8.73483 0.707031 9L0.707031 18.9998C0.707031 19.265 0.812372 19.5193 0.999879 19.7068C1.18739 19.8943 1.4417 19.9997 1.70687 19.9997H4.70688V22.9997C4.70681 23.1311 4.73265 23.2612 4.78289 23.3826C4.83314 23.504 4.90682 23.6143 4.99972 23.7072C5.09262 23.8001 5.20292 23.8737 5.32431 23.924C5.4457 23.9742 5.57581 24.0001 5.70719 24H19.7069C19.972 24 20.2264 23.8947 20.4139 23.7072C20.6014 23.5196 20.7067 23.2653 20.7067 23.0002V20.0002H23.7067C23.8381 20.0002 23.9682 19.9744 24.0896 19.9241C24.211 19.8739 24.3213 19.8002 24.4142 19.7073C24.5071 19.6144 24.5808 19.5041 24.631 19.3827C24.6813 19.2613 24.7071 19.1312 24.707 18.9998V9C24.707 8.73483 24.6017 8.48051 24.4142 8.293C24.2267 8.1055 23.9724 8.00016 23.7072 8.00016ZM6.70703 2.00016H18.707V8.00016H6.70703V2.00016ZM18.707 21.9998H6.70703V18.9998H18.707V21.9998ZM19.7069 15C19.5091 15 19.3158 14.9414 19.1514 14.8315C18.987 14.7216 18.8588 14.5655 18.7831 14.3828C18.7075 14.2001 18.6877 13.999 18.7262 13.8051C18.7648 13.6111 18.86 13.433 18.9999 13.2932C19.1397 13.1533 19.3179 13.0581 19.5118 13.0195C19.7058 12.9809 19.9068 13.0007 20.0895 13.0764C20.2722 13.1521 20.4284 13.2803 20.5382 13.4447C20.6481 13.6091 20.7067 13.8024 20.7067 14.0002C20.7067 14.2653 20.6014 14.5196 20.4139 14.7072C20.2264 14.8947 19.972 15 19.7069 15Z" fill="#5E6464"/>
        </svg>
    )
}

export default PrintIcon