import React from 'react'

const LinkIcon = () => {

    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3196 5.04754L15.9046 3.46263C17.6552 1.712 20.4935 1.71199 22.2442 3.46263V3.46263C23.9948 5.21327 23.9948 8.05162 22.2442 9.80225L16.8102 15.2362C15.0596 16.9869 12.2212 16.9869 10.4706 15.2362V15.2362L10.2442 15.0098" stroke="#5E6464" strokeWidth="2" strokeLinecap="round"/>
            <path d="M11.0944 20.9523L9.50951 22.5372C7.75887 24.2879 4.92052 24.2879 3.16988 22.5372V22.5372C1.41925 20.7866 1.41925 17.9483 3.16988 16.1976L8.60385 10.7637C10.3545 9.01302 13.1928 9.01302 14.9435 10.7637V10.7637L15.1699 10.9901" stroke="#5E6464" strokeWidth="2" strokeLinecap="round"/>
        </svg>

    )
}

export default LinkIcon