import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { EmailShareButton, FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share'
import EnvelopeIcon from '../svgs/EnvelopeIcon'
import LinkIcon from '../svgs/LinkIcon'
import PrintIcon from '../svgs/PrintIcon'

const ShareBar = ( uri ) => {
    
    const url                 = 'https://clearlyreformed.org' + uri.url;
	const [ print, setPrint ] = useState( false );

    function handlePrint() {
        setPrint( true )
    }

    useEffect( () => {
        if ( print ) {
            window.print();
        }
    }, [print] );

    return (
        <div className="social-share sticky bg-white top-0 pt-2 pb-4 lg:pt-0 lg:pb-8 lg:top-8 lg:ml-16 flex lg:flex-col gap-4 lg:gap-8 w-100 lg:w-8 justify-center border-b lg:border-0 print:hidden">
            <FacebookShareButton url={url}><FacebookIcon size="24" borderRadius="6" bgStyle={{ fill: "#5E6464" }} /></FacebookShareButton>
            <TwitterShareButton url={url}><TwitterIcon size="24" borderRadius="6" bgStyle={{ fill: "#5E6464" }} /></TwitterShareButton>
            <div className="cursor-pointer" onClick={handlePrint} ><PrintIcon /></div>
            <EmailShareButton subject="Check out this resource from ClearlyReformed.org" body="I thought you might like this resource from ClearlyReformed.org " url={url}><EnvelopeIcon /></EmailShareButton>
            <CopyToClipboard text={url}><button><LinkIcon/></button></CopyToClipboard>
        </div>
    )
}

export default ShareBar