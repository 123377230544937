import React from 'react'
import Button from '../Button'

const SinglePagination = ({previous, next}) => {

    return (
        <div className="border-t border-b py-4 lg:py-8 px-4 lg:px-16 flex print:hidden">
            <div className="flex-1 text-left">
                { previous && 
                    <Button
                        link={previous}
                        text="Newer Post"
                        position="left"
                    />
                }
            </div>
            <div className="flex-1">
                {next && 
                    <Button
                        link={next}
                        text="Older Post"
                        position="right"
                    />
                }
            </div>
        </div>
    )
}

export default SinglePagination