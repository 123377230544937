import React, { useEffect, useState } from 'react'
import Loop from '../Loop'
import SectionHeader from '../SectionHeader';

const RelatedContent = ({topic, category, currentPost }) => {

    const [related, setRelated] = useState([]);

    useEffect( () => {

        const filters = {
            category: '',
            format: '',
            topic: topic,
            scripture: '',
            page: '',
            search: '',
            exclude: currentPost,
            perPage: 4,
            relation: 'OR'
        }

        async function resourceSecondaryFetch() {
            filters.topic = '';
            filters.search = topic;
            const response = await window
                .fetch( `/api/resources/filter`, {
                    method: `POST`,
                    headers: {
                        "content-type": "application/json",
                    },
                    body: JSON.stringify( filters ),
                })
                .then(res => res.json() )

                setRelated( response.resources );

        }

        async function resourceFetch() {

            const response = await window
                .fetch( `/api/resources/filter`, {
                    method: `POST`,
                    headers: {
                        "content-type": "application/json",
                    },
                    body: JSON.stringify( filters ),
                })
                .then(res => res.json() )

                if ( response.resources.length < 4 ) {
                    resourceSecondaryFetch()
                } else {
                    setRelated( response.resources );
                }
        }

        resourceFetch();
    }, [])

    return (
        <div className="container mx-auto py-24">
            <SectionHeader
                label="You might also like"
            />
            <Loop
                items={related}
            />
        </div>
    )
}

export default RelatedContent